<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="8">
                    <v-card>
                        <v-card-title>
                            Total Users
                        </v-card-title>
                        <v-card-text>
                            {{ getTotalUserCount }} Users
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- <v-col cols="4">
                    <v-card>
                        <v-card-title>
                            Recent Visitors
                        </v-card-title>
                        <v-card-text>
                            {{totalVisitors}} Visitors
                        </v-card-text>
                    </v-card>
                </v-col> -->
                <!-- <v-col cols="4">
                    <v-card>
                        <v-card-title>
                            Downloads
                        </v-card-title>
                        <v-card-text>
                            {{totalDownloads}} Downloads
                        </v-card-text>
                    </v-card>
                </v-col> -->
            </v-row>
            <v-row>
                <!-- <v-col cols="8">
                    <v-card>
                        <v-card-title>
                            Reports
                        </v-card-title>
                        <v-card-text>
                            <v-sparkline
                                :value="value"
                                :gradient="gradient"
                                :smooth="radius || false"
                                :padding="padding"
                                :line-width="width"
                                :stroke-linecap="lineCap"
                                :gradient-direction="gradientDirection"
                                :fill="fill"
                                :type="type"
                                :auto-line-width="autoLineWidth"
                                auto-draw
                            ></v-sparkline>
                        </v-card-text>
                    </v-card>
                </v-col> -->
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                            State
                        </v-card-title>
                        <v-card-subtitle>
                            User count by state
                        </v-card-subtitle>
                        <v-card-text>
                            <v-row>
                                <v-col cols="6" :key="index" v-for="(stateUserCt, index) in stateUserCounts">
                                    {{ stateUserCt.state.length > 0 ? stateUserCt.state : 'No State' }} - {{ stateUserCt.ct
                                    }} ( {{ getPercentage(stateUserCt.ct, getTotalUserCount) }}% )
                                    <v-progress-linear height="8"
                                        :value="getPercentage(stateUserCt.ct, getTotalUserCount)"></v-progress-linear>
                                </v-col>
                            </v-row>
                            <!--                            <v-row>
                                <v-col cols="6">
                                    Uttar Pradesh - {{percent["Uttar_Pradesh"]}}
                                    <v-progress-linear height="8" :value="percent['Uttar_Pradesh']"></v-progress-linear>
                                </v-col>
                                <v-col cols="6">
                                    Delhi - {{percent["Delhi"]}}
                                    <v-progress-linear height="8" :value="percent['Delhi']"></v-progress-linear>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    Gujrat - {{percent['Gujrat']}}
                                    <v-progress-linear height="8" :value="percent['Gujrat']"></v-progress-linear>
                                </v-col>
                                <v-col cols="6">
                                    Maharashtra - {{percent['Maharashtra']}}
                                    <v-progress-linear height="8" :value="percent['Maharashtra']"></v-progress-linear>
                                </v-col>
                                <v-col cols="6">
                                    Punjab - {{percent['Mizoram']}}
                                    <v-progress-linear height="8" :value="percent['Mizoram']"></v-progress-linear>
                                </v-col>
                                <v-col cols="6">
                                    Tamilnadu - {{percent['TamilNadu']}}
                                    <v-progress-linear height="8" :value="percent['TamilNadu']"></v-progress-linear>
                                </v-col>
                                <v-col cols="6">
                                    Bihar {{percent['Bihar']}}
                                    <v-progress-linear height="8" :value="percent['Bihar']"></v-progress-linear>
                                </v-col>
                            </v-row>-->
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

const gradients = [
    ['#222'],
    ['#42b3f4'],
    ['red', 'orange', 'yellow'],
    ['purple', 'violet'],
    ['#00c6ff', '#F0F', '#FF0'],
    ['#f72047', '#ffd200', '#1feaea'],
]

export default {
    data: () => ({
        width: 2,
        radius: 10,
        padding: 8,
        lineCap: 'round',
        gradient: ['#2D41C2', '#2D41C2'],
        value: [58, 30, 58, 38, 20, 50, 10, 36, 88, 58, 100],
        gradientDirection: 'top',
        gradients,
        fill: false,
        type: 'trend',
        autoLineWidth: false,
        totalUsers: 0,
        totalVisitors: 0,
        totalDownloads: 0,
        percent: {
            Uttar_Pradesh: 0,
            West_Bengal: 0,
            Odisha: 0,
            Delhi: 0,
            Gujrat: 0,
            Mizoram: 0,
            Maharashtra: 0,
            TamilNadu: 0,
            Bihar: 0
        },
        dashboard: {}
    }),
    created() {
        this.getDashboardDetails();
    },
    methods: {
        getUsers() {
            this.$Axios.get('/api/v1/util/get-total-user')
                .then(({ data }) => {
                    this.totalUsers = data.total_user
                }).catch(e => console.log(e))
        },
        getVisitors() {
            this.$Axios.get('get-acitve-users')
                .then(({ data }) => {
                    this.totalVisitors = data.totalCount
                }).catch(e => console.log(e))
        },
        getDownloads() {
            this.$Axios.get('get-download-count')
                .then(({ data }) => {
                    this.totalDownloads = data.totalDownloadCount
                }).catch(e => console.log(e))
        },
        getState(state) {
            this.$Axios.get('get-total-users/bystate?state=' + state)
                .then(({ data }) => {
                    this.percent[state] = data.usersByState
                }).catch(e => console.log(e))
        },
        getDashboardDetails() {
            // TODO: Mustansir add dashboard data.
            this.$Axios.get('/api/v1/util/dashboard_details')
                .then(({ data }) => {
                    this.dashboard = data;
                }).catch(e => {
                    console.log(e);
                    alert(e.response?.data?.msg ?? '');
                })
        },
        getPercentage(current, total) {
            return ((current / total) * 100).toFixed(1);
        }
    },
    computed: {
        getTotalUserCount() {
            return this.dashboard.total !== undefined && this.dashboard.total !== null ? this.dashboard.total : 0;
        },
        getTotalActiveUserCount() {
            return this.dashboard.totalActive !== undefined && this.dashboard.totalActive !== null ? this.dashboard.totalActive : 0;
        },
        stateUserCounts() {
            return this.dashboard.stateUserCount !== undefined
                && this.dashboard.stateUserCount !== null
                && Array.isArray(this.dashboard.stateUserCount)
                ? this.dashboard.stateUserCount : [];
        }
    }
}
</script>